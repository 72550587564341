import React, { useState, forwardRef } from "react";
import TextField from "./index";
import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

export default forwardRef(function PasswordInput(props, ref) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      ref={ref}
      type={showPassword ? "text" : "password"}
      {...props}
      InputProps={{
        endAdornment: !showPassword ? (
          <IconButton onClick={handleClickShowPassword}>
            <VisibilityOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleClickShowPassword}>
            <VisibilityOffOutlinedIcon />
          </IconButton>
        ),
      }}
    />
  );
});
