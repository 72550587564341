import SmartJobPosting from "assets/images/login/01_Talent Acquisition_Job Posting.png";
import AutomatedApplicantScreening from "assets/images/login/02_Candidate_Screening_ Interview_Chatbot.png";
import InterviewSchedule from "assets/images/login/03_Interview_Scheduling_and_Video_Interviews.png";
import EasyCandidate from "assets/images/login/04_ATS_Recruitment_Automation.png";

export default (intl) => [
  {
    image: SmartJobPosting,
    title: intl.formatMessage({
      id: "Smart Job Posting",
      defaultMessage: "Smart Job Posting",
    }),
    desc: intl.formatMessage({
      id: "Jobs are reposted on 20+ top-tier job boards for increased reach.",
      defaultMessage:
        "Jobs are reposted on 20+ top-tier job boards for increased reach.",
    }),
  },
  {
    image: AutomatedApplicantScreening,
    title: intl.formatMessage({
      id: "Automated Applicant Screening",
      defaultMessage: "Automated Applicant Screening",
    }),
    desc: intl.formatMessage({
      id: "Save 8 hours a week by focusing on the right candidates.",
      defaultMessage:
        "Save 8 hours a week by focusing on the right candidates.",
    }),
  },
  {
    image: InterviewSchedule,
    title: intl.formatMessage({
      id: "Flawless Interview Scheduling",
      defaultMessage: "Flawless Interview Scheduling",
    }),
    desc: intl.formatMessage({
      id: "No more time wasted with back & forth invitations and reminders.",
      defaultMessage:
        "No more time wasted with back & forth invitations and reminders.",
    }),
  },
  {
    image: EasyCandidate,
    title: intl.formatMessage({
      id: "Easy Candidate Management",
      defaultMessage: "Easy Candidate Management",
    }),
    desc: intl.formatMessage({
      id: "Track applicants at every step of your recruitment funnel.",
      defaultMessage:
        "Track applicants at every step of your recruitment funnel.",
    }),
  },
];
