import React from "react";
import Logo from "components/Logo";
import { Box } from "@mui/material";

export default function AuthLogo() {
  return (
    <Box mt={3} ml={4}>
      <Logo width={220} height={30} />
    </Box>
  );
}
