import React, { forwardRef } from "react";
import {
  FormControl,
  OutlinedInput,
  FormLabel,
  FormHelperText,
  Typography,
  Stack,
} from "@mui/material";

export default forwardRef(function CustomTextField(
  {
    label,
    required,
    error,
    fullWidth = true,
    size = "small",
    sx,
    controlProps,
    InputLabelProps,
    InputProps,
    helperText,
    colorBlack,
    labelProps,
    ...props
  },
  ref
) {
  const hasError = !!error;
  return (
    <FormControl
      {...controlProps}
      required={required}
      error={hasError}
      fullWidth={fullWidth}
    >
      <Stack
        direction={{ sm: "column", xs: "column", md: "row", lg: "row" }}
        {...labelProps}
      >
        {label && (
          <FormLabel
            sx={{ color: colorBlack && "#313638" }}
            {...InputLabelProps}
          >
            {label}
          </FormLabel>
        )}{" "}
        {error && (
          <FormHelperText error={hasError}>{error?.message}</FormHelperText>
        )}
      </Stack>
      <OutlinedInput
        ref={ref}
        {...props}
        {...InputProps}
        error={hasError}
        size={size}
        sx={sx}
      />

      {helperText && (
        <FormHelperText>
          <Typography color="secondary" variant="body2">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
});
