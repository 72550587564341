import React, { useState, useEffect } from "react";
import { StyledLoginForm } from "./styles";
import { IconArrowNarrowRight } from "@tabler/icons";
import { CircularProgress } from "@mui/material";

import { useIntl } from "react-intl";

import TextField from "components/CustomTextField";
import PasswordField from "components/CustomTextField/password";

import { yupResolver } from "@hookform/resolvers/yup";
import NextLink from "next/link";
import {
  Grid,
  Link,
  Button,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";

import postLoginSchema from "validations/postLoginSchema";
import { useRouter } from "next/router";

import CustomCheckbox from "components/CustomCheckbox";

import {
  APP_BASE,
  HRMS_BASE,
  ROUTE_FORGET_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_DASHBOARD,
  ROUTE_REGISTER_COMPLETE,
} from "config/routes";

import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import { URL_LOGIN } from "config/endpoints";
import { postFetcher } from "utils/fetcher";
import getRememberedUser from "services/auth/get-remembered-user";
import { ERR_NOT_VERIFIED } from "config/auth";
import { TRACK_ACCOUNT_VERIFIED } from "config/tracking";
import { track } from "hooks/useAnalytics";
import { clearSessionStorage } from "hooks/useSessionStorage";
import { showBanner } from "services/dashboard/promotion-banner-cookie";

export default function LoginForm({ isVerifying }) {
  const router = useRouter();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    query: { r },
  } = router;
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(postLoginSchema(intl)),
    shouldUseNativeValidation: false,
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const getRedirectUrl = () => {
    const url = r ?? ROUTE_DASHBOARD;
    return `${r?.startsWith("/hr") ? HRMS_BASE : APP_BASE}${url}`;
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await postFetcher(URL_LOGIN, data);
      track(TRACK_ACCOUNT_VERIFIED, { email: data.email });
      showBanner(true);
      clearSessionStorage();
      router.push(getRedirectUrl());
    } catch (e) {
      if (e.info?.err_msg === ERR_NOT_VERIFIED) {
        return router.push({
          pathname: ROUTE_REGISTER_COMPLETE,
          query: { user: data.email },
        });
      }
      setError("form", {
        type: "manual",
        message: e.info?.err_msg,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const user = getRememberedUser();
    if (user) {
      onSubmit(user);
    }
  }, []);

  return (
    <StyledLoginForm
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={intl.formatMessage({
                  id: "Email",
                  defaultMessage: "Email",
                })}
                type="email"
                placeholder={intl.formatMessage({
                  id: "Enter Email",
                  defaultMessage: "Enter Email",
                })}
                error={error}
                {...field}
                autoFocus
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState: { error } }) => (
              <PasswordField
                label={intl.formatMessage({
                  id: "Password",
                  defaultMessage: "Password",
                })}
                placeholder={intl.formatMessage({
                  id: "Enter Password",
                  defaultMessage: "Enter Password",
                })}
                error={error}
                {...field}
                required
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container mt={2}>
        <Grid item xs>
          <Controller
            control={control}
            name="remember"
            render={({ field }) => (
              <CustomCheckbox
                label={intl.formatMessage({
                  id: "Remember me",
                  defaultMessage: "Remember me",
                })}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item mt={1}>
          <NextLink href={ROUTE_FORGET_PASSWORD} passHref>
            <Link
              underline="none"
              color="secondary"
              sx={{ lineHeight: "100%" }}
            >
              {intl.formatMessage({
                id: "Forgot password?",
                defaultMessage: "Forgot password?",
              })}
            </Link>
          </NextLink>
        </Grid>
      </Grid>
      {errors.form && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{errors.form.message}</FormHelperText>
        </Box>
      )}
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        color="primary"
        endIcon={
          isVerifying || loading ? (
            <CircularProgress size={20} color="grey" />
          ) : (
            <IconArrowNarrowRight />
          )
        }
        disabled={isVerifying || loading}
      >
        {intl.formatMessage({
          id: "Login",
          defaultMessage: "Login",
        })}
      </Button>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        sx={{
          textAlign: "center",
          width: "100%",
        }}
        mt={{ lg: 2 }}
      >
        {intl.formatMessage({
          id: "New to GrabJobs?",
          defaultMessage: "New to GrabJobs?",
        })}
        &nbsp;
        <NextLink href={ROUTE_REGISTER} passHref>
          <Link color="secondary">
            {intl.formatMessage({
              id: "Sign up now!",
              defaultMessage: "Sign up now!",
            })}
          </Link>
        </NextLink>
      </Typography>
    </StyledLoginForm>
  );
}
