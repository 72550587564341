//signup
export const TRACK_USER_REGISTER = "registrationComplete";
export const TRACK_ACCOUNT_VERIFIED = "accountVerified";
export const TRACK_FREE_TRIAL_STARTED = "StartFreeTrial";
export const TRACK_VIEW_REGISTER = "LandingPageSignUp";

//onboarding
export const TRACK_ADD_COMPANY_INFO = "fillCompanyInfo";
export const TRACK_ADD_COMPANY_INFO_FILL = "CompleteBrandProfile";
export const TRACK_POST_A_JOB = "postAJob";
export const TRACK_VIEW_GUIDE = "ViewGuide";

export const TRACK_VIEW_HOMEPAGE = "viewHomePage";
export const TRACK_LANDING_SIGNUP = "landingPageSignUp";
export const TRACK_TRIAL_START_DATE = "trialStartDate";
export const TRACK_DASHBOARD_BUMPJOBS = "DashboardBumpJobsClick";
export const TRACK_LOGIN_SUCCESS = "LoginSuccess";

//jobs
export const TRACK_VIEW_CANDIDATE_PROFILE = "ViewApplicantProfile";
export const TRACK_SHORTLIST_CANDIDATE = "ShortlistingApplicant";
export const TRACK_ATS_BUMP_JOB = "GetMoreBumpJobClick";
export const TRACK_CONFIRM_BUMP_JOB = "ConfirmBumpJobs";
export const TRACK_GET_MORE_APPLICANTS = "GetMoreApplicantsClick";
export const TRACK_CANDIDATE_MOVE = "MoveToCandidate";
export const TRACK_GET_MORE_APPLICANT_EMAIL_CLICK =
  "GetMoreApplicantEmailClick";
export const TRACK_VIEW_TOP_MATCHCANDIDATEPROFILE =
  "ViewTopMatchCandidateProfile";
export const TRACK_VIEW_ALL_TOP_MATCHCANDIDATES = "ViewAllTopMatchCandidates";
export const TRACK_RECEIVE_NEW_APPLICANTS = "ReceivedApplicant";
export const TRACK_PROMTE_JOB_CAMPAIGN = "PromoteViaCampaignClick ";
export const TRACK_PROMOTE_JOB_PAGE = "PromoteJobClick";
export const TRACK_TOP_MATCHING_VIEW = "TopMatchingClick";
export const TRACK_TOP_MATCHING_PROFILE_CLICK = "ViewTopMatchProfile;Click";
export const TRACK_TOP_MATCHING_INVITE_CLICK = "InviteToApplyClick";
export const TRACK_CONFIRM_INVITE_APPLY_CLICK = "ConfirmInviteToApplyClick";
export const TRACK_SHORTLIST_NEW_APPLICANT_CLICK = "ShortlistNewApplicantClick";
export const TRACK_PROFILE_SHORTLIST_APPLICANT_CLICK =
  "ProfileShortlistApplicantClick";
export const TRACK_ATS_MOVETONEXT = "MoveToClick";
export const TRACK_JOB_VIEW_GET_MORE = "JobViewGetMoreClick";
export const TRACK_GET_MORE_JOB_BOOST = "GetMoreApplicantBoostClick";
export const TRACK_BOOST_ACTIVATE_CLICK = "BoostActivatenowClick";
export const TRACK_BOOST_COIN_TOPUP_CLICK = "BoostCoinTopUpClick";
export const TRACK_PIN_JOB_CLICK = "PinJobClick";
export const TRACK_PIN_JOB_CONFIRM_CLICK = "PinJobConfirmClick";
export const TRACK_PIN_JOB_COIN_TOPUP_CLICK = "PinJobCoinTopUpClick";
export const TRACK_PIN_JOB_REMIND_CLICK = "PinJobRemindClick";
export const TRACK_JOB_VIEWS_CLLICK = "JobViewsClick";
export const TRACK_PROMOTE_PIN_JOB_CLICK = "PromoteJobPinJobClick";
export const TRACK_NEW_APPLICANT_NAV = "NewApplicantNavClick";
export const TRACK_SHORTLIST_NAV = "ShortlistNavClick";
export const TRACK_INTERVIEW_NAV = "InterviewNavClick";
export const TRACK_OFFER_NAV = "OfferNavClick";
export const TRACK_HIRE_NAV = "HireNavClick";
export const TRACK_DISQUALIFIED_NAV = "DisqulifiedNavClick";

//interview
export const TRACK_SEND_INTERVIEW_CLICK = "SendInterviewClick";
export const TRACK_SCHEDULE_INTERVIEW = "ScheduleInterview";
export const TRACK_SCHEDULE_INTERVIEW_SELECT_SLOT =
  "ScheduleInterviewSelectSlot";
export const TRACK_SCHEDULE_INTERVIEW_FIXED_TIME = "ScheduleInterviewFixedTime";
export const TRACK_SCHEDULE_INTERVIEWED = "ScheduleInterviewMaked";
export const TRACK_ADD_CALENDAR = "AddCalendar";
export const TRACK_CALENDAR_DAY = "CalendarDayClick";
export const TRACK_CONFIRM_CREATE_TIME_SLOT = "ConfirmCreateTimeSlot";
//interview Calendar
export const TRACK_INTERVIEW_CALENDAR = "InterviewCalenderClick";

//prototype
export const TRACK_VIEW_SHORTLIST_GUIDE = "ViewShortlistGuideClick";
export const TRACK_VIEW_OFFER_GUIDE = "ViewOfferGuideClick";
export const TRACK_CLOSE_GUIDE = "CloseGuide";
export const TRACK_BACKTO_SHORTLIST_GUIDE = "BackToShortlistGuideClick";
export const TRACK_VIEW_PRODUCT_GUIDE = "ViewProductGuideClick";
//analytics
export const TRACK_ANALYTICS_VIEW = "AnalyticsClick";

//job posting
export const TRACK_JOB_POST_STEP1 = "Pro_Step1Complete";
export const TRACK_JOB_POST_STEP2 = "Pro_Step2Complete";
export const TRACK_JOB_POST_STEP3 = "Pro_Step3Complete";
export const TRACK_JOB_POST_SUBMIT = "Pro_SubmitPost";
export const TRACK_JOB_POST_SELECT = "SelectPlan_Pro";
export const TRACK_GENERATE_JOB_DESCRIPTION = "GenerateJobDescriptionClick";
export const TRACK_RECOMMEND_SALARY_CLICK = "Recommend SalaryClick";

export const TRACK_URL_JOB_POST_STEP1 = "URL_Step1Complete";
export const TRACK_URL_JOB_POST_STEP2 = "URL_Step2Complete";
export const TRACK_URL_JOB_POST_SUBMIT = "URL_SubmitPost";
export const TRACK_URL_JOB_POST_SELECT = "SelectPlan_URLredirect";

export const TRACK_MANAGE_JOB_AFTER_POST = "ManageJobClick";
export const TRACK_PREVIEW_JOB_APPLICATION = "PreviewJobApplicationClick";
export const TRACK_COPY_APPLICATION_LINK = "CopyJobApplicationClick";
export const TRACK_SHARE_JOB_FACEBOOK = "ShareFacebookClick";
export const TRACK_SHARE_JOB_LINKEDIN = "ShareLinkedinClick";
export const TRACK_SHARE_JOB_WHATSAPP = "ShareWhatsappClick";

//plan and subscription
export const TRACK_PLAN_UPGRADED = "PlanUpgraded";
export const TRACK_PURCHASE_COMPLETE = "purchaseComplete";
export const TRACK_SUBSCRIPTION_CANCEL = "PlanCancel";
export const TRACK_PLAN_DOWNGRADED = "PlanDowngrade";
export const TRACK_PLAN_CLICK = "ClickPlanUpgrade";
export const TRACK_CANCEL_PLAN = "CanclePlanClick";
export const TRACK_DOWNGRADE_CANDIDATE_PLAN = "DownGradeCandidatePlanClick";
export const TRACK_CONFIRM_CANCLE_PLAN = "ConfirmCancelPlanClick";

//coin
export const TRACK_COIN_TOPUP = "CoinTopUp";
export const TRACK_COIN_TOPUP_SKIP = "SkipTopUpCoin";
export const TRACK_COIN_ONE_TIME_PURCHASE = "OneTimeCoinPurchaseSuccess";
export const TRACK_COIN_ONE_TIME_PURCHASE_SKIP = "OneTimeCoinPurchaseSkip";
export const TRACK_COIN_GO_TO_PLANS = "CoinToPlan";
export const TRACK_COIN_TOPUP_TYPE = "CoinTopUpTypeClick";
//brand
export const USERPILOT_UPDATE_BRAND = "UpdateBrand";

//offer
export const TRACK_OFFER_FROM_INTERVIEW = "OfferFromInterview";
export const TRACK_OFFER = "Offer";
export const TRACK_INTERVIEW_SEND_OFFER_CLICK = "InterviewSendOfferClick";
export const TRACK_SEND_OFFER_NEXT_CLICK = "SendOfferNextClick";
export const TRACK_OFFER_SEND_OFFER_CLICK = "OfferSendOfferClick";
export const TRACK_PDF_PREVIEW_SEND = "PDFPreviewSendClick";
export const TRACK_PDF_PREVIEW_CANCEL = "PDFPreviewCancelClick";
export const TRACK_CONFIRMED_SEND_OFFER = "ConfirmedSendOfferClick";
export const TRACK_DOWNLOAD_CONTRACT = "DownloadContractClick";
export const TRACK_DECLINED_RESEND_OFFER = "DeclineResendOffer";
export const TRACK_EXPIRED_RESEND_OFFER = "ExpiredResendOffer";

//candidate search
export const TRACK_CANDIDATE_PAGE_VIEW = "CVSearchMenuClick";
export const TRACK_CANDIDATE_CONFIRM_SEARCH = "CVSearchClick";
export const TRACK_ADD_MYCANDIDATES = "AddtoMyCandidatesClick";
export const TRACK_NOPLAN_CVSERACH = "NoPlanCVSearchClick";
export const TRACK_CVSEARCH_TOPUP_COINS = "CVSearchTopUpCoinsClick";
export const TRACK_CVSEARCH_TOPUP_COINS_SUCCESS = "CVSearchTopUpCoinsSuccess";
export const TRACK_CONFIRM_SEARCH_SHORTLIST_CLICK =
  "ConfirmSearchShortlistClick";
export const TRACK_CVYEARLY_CLICK = "CVYearlyClick";
export const TRACK_CVMONTHLY_CLICK = "CVMonthlyClick";
export const TRACK_CVCONTACTUS_CLICK = "CVContactUsClick";
//Chat
export const TRACK_CHAT_TAB_CLICK = "ChatTabClick";
export const TRACK_CHAT_MESSAGE_SEND = "ChatTabSendMessageClick";
export const TRACK_CHAT_CLICK = "ChatClick";
export const TRACK_CHAT_PAGE_MESSAGE_SEND = "ChatPageSendMessageClick";
export const TRACK_PROFILE_LIVE_CHAT = "ProfileLiveChatClick";
