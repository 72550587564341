import React, { useRef } from "react";
import { Button, Box } from "@mui/material";
import { useLocale } from "hooks/useLocale";
import getLanguages from "./languages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useIntl } from "react-intl";
import LanguageMenu from "./menu";

export default function LanguageSelect(props) {
  const intl = useIntl();
  const menuRef = useRef();
  const { locale } = useLocale();
  const languages = getLanguages(intl).sort((a, b) =>
    a.code > b.code ? 1 : -1
  );

  const onOpen = (event) => menuRef.current.open(event.currentTarget);

  return (
    <Box {...props}>
      <Button
        size="small"
        onClick={onOpen}
        endIcon={<ArrowDropDownIcon />}
        color="grey"
        fullWidth={false}
      >
        {languages.find((l) => l.code === locale).title}
      </Button>
      <LanguageMenu ref={menuRef} intl={intl} />
    </Box>
  );
}
