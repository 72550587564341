import * as yup from "yup";

export default (intl) =>
  yup.object({
    email: yup
      .string()
      .email()
      .required(
        intl.formatMessage({
          id: "Email is required.",
          defaultMessage: "Email is required.",
        })
      ),
    password: yup
      .string()
      .label()
      .required(
        intl.formatMessage({
          id: "Password is required.",
          defaultMessage: "Password is required.",
        })
      ),
  });
