import React, { useEffect, useState } from "react";
import images from "./images";

import { Stack, Typography } from "@mui/material";

import Banner from "../Common/banner";
import PublicLayout from "components/Layout/Public";
import LanguageSelect from "components/LanguageSelect";
import { useIntl } from "react-intl";
import LoginForm from "./form";
import { useRouter } from "next/router";
import { URL_VERIFY } from "config/endpoints";
import { postFetcher } from "utils/fetcher";
import { track } from "hooks/useAnalytics";
import { TRACK_VIEW_HOMEPAGE } from "config/tracking";
import { REGISTER_SOURCE } from "config/app";
import { ROUTE_DASHBOARD } from "config/routes";

export default function Login() {
  const intl = useIntl();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { utm_source } = router.query;
  useEffect(() => {
    track(TRACK_VIEW_HOMEPAGE, { source: utm_source || REGISTER_SOURCE });
    const {
      query: { verify },
    } = router;

    if (verify) {
      setLoading(true);
      (async () => {
        try {
          await postFetcher(URL_VERIFY, { token: verify });
          router.push(`${ROUTE_DASHBOARD}?welcome`);
        } catch (e) {
          setLoading(false);
        }
      })();
    }
  }, [router]);

  return (
    <PublicLayout background={<Banner images={images} />}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="flex-end">
          <LanguageSelect />
        </Stack>

        <Typography component="p" variant="h4" fontWeight="bold">
          {intl.formatMessage({
            id: "Employer Login",
            defaultMessage: "Employer Login",
          })}
        </Typography>
        <Typography component="p" variant="h5" color="text.secondary">
          {intl.formatMessage({
            id: "Welcome back!",
            defaultMessage: "Welcome back!",
          })}
        </Typography>
        <LoginForm isVerifying={loading} />
      </Stack>
    </PublicLayout>
  );
}
