import React, { useState, useEffect, useCallback } from "react";
import Image from "next/image";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import Logo from "components/Authentication/Logo";
import { useLocale } from "hooks/useLocale";

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  width: "85%",
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(6),
  marginTop: theme.spacing(4),
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
}));

export default function Banner({ images }) {
  const intl = useIntl();
  const { locale } = useLocale();
  const [random, setRandom] = useState(Math.floor(Math.random() * 4));
  const [image, setImage] = useState(images(intl)[0]);

  useEffect(() => {
    const intervalId = setInterval(
      () => setRandom(Math.floor(Math.random() * 4)),
      5000
    );
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setImage(images(intl)[random]);
  }, [locale, random]);

  return (
    <Box mt={1.5} sx={{ width: "100%" }}>
      <Logo />
      <Grid sx={{ paddingX: 20, paddingY: 5 }}>
        <Image
          src={image.image}
          loading="lazy"
          layout="responsive"
          placeholder="blur"
        />
      </Grid>
      <TitleBox>
        {image.title}
        <Typography sx={{ fontSize: 13 }}>{image.desc}</Typography>
      </TitleBox>
    </Box>
  );
}
