import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContainerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  paddingLeft: "50%",
  backgroundColor: theme.palette.grey.light,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
  },
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "50%",
  left: 0,
  top: 10,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  paddingTop: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  backgroundColor: theme.palette.white.main,
  minHeight: "100vh",
}));

export default function PublicLayout({ background: Background, children }) {
  return (
    <ContainerBox>
      <BackgroundBox>{Background}</BackgroundBox>
      <ContentBox>{children}</ContentBox>
    </ContainerBox>
  );
}
